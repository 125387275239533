import React from 'react'

const Page = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[400px] text-[40px] space-y-[10px]">
      <div>🚧</div>
      <div>找不到页面</div>
    </div>
  )
}

export default Page
